.card-container {
  width: 288px;
  height: 312px;
  background: #008868;
  border-radius: 8px;
  @media only screen and (max-width: 692px) {
    width: 328px;
    min-width: 328px;
  }
  padding: 5px;
}

a.disabled {
  cursor: default;
}

.card-content {
  border: 2px solid #ffffff;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  padding: 32px 25px;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 3.85px;
  background-color: #ffffff;
  width: 110px;
  height: 46px;
  border-radius: 4px;
  margin-top: 16px;
}

.tag-text {
  
  height: 39px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #3dd598;
}

.title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #3dd598;
}

.title-container {
  margin-bottom: 16px;
}

.text-container {
  width: 100%;
  min-height: 170px;
  max-height: 170px;
}

.text {
  font-weight: 600;

  line-height: 32px;
  letter-spacing: -1px;
  color: #ffffff;
}

.learn-more {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.88) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
