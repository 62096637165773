.cardMobile {
  display: none;
}

.cardDesktop {
  display: flex;
}

@media only screen and (max-width: 692px) {
  .cardMobile {
    display: flex;
    width: 328px;
  }

  .cardDesktop {
    display: none;
  }

  .card-container {
    width: 328px;
    min-width: 328px;
  }

  .card-container-2 {
    width: 328px;
    min-width: 328px;
  }

}

.container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.onHover:hover {
  filter: opacity(0.8);
}

.outOfStock {
  margin-top: 10px;
  margin-left: 20px;
  background: linear-gradient(180deg,
      rgba(4, 21, 38, 0) 0%,
      rgba(2, 28, 55, 0.7) 150%);
  background-color: rgba(250, 250, 250, 0.7);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08), 0px 5px 10px rgba(0, 0, 0, 0.04),
    0px 10px 18px rgba(0, 0, 0, 0.02), 0px 16px 30px rgba(0, 0, 0, 0.04),
    0px 40px 80px rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  padding: 2px;
  width: 125px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.textOutOfStock {
  font-size: 16px;
  color: #021120;
  font-weight: 400;
}

.redirectionLink {
  cursor: pointer;
}

.divName {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.name {
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-align: start;
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.goalsModal {
  margin-left: 5px;
  color: #777e8b;
}

.kind {
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 5px;
}

.divFlag {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
}

.back {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
}

.flag {
  width: 20px;
  height: 15px;
}

.mount {
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.divGoals {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08), 0px 5px 10px rgba(0, 0, 0, 0.04),
    0px 10px 18px rgba(0, 0, 0, 0.02), 0px 16px 30px rgba(0, 0, 0, 0.04),
    0px 40px 80px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 2px;
  width: 56px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nomber {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  margin-left: 2px;
}

.degrade {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: linear-gradient(180deg,
      rgba(2, 17, 32, 0) 0%,
      rgba(2, 17, 32, 0.7) 100%);
  height: 50%;
  width: 100%;
  bottom: 0;
  border-radius: 5px;
}

.card-container {
  width: 288px;
}

.card-container-2 {
  width: 288px;
}