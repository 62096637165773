.modalBody::-webkit-scrollbar {
  display: none;
}

.filtros {
  margin-bottom: 150px;
}

.container-category {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.filter-text {
  font-size: 16px;
  font-weight: bold;
}

.type-filter {
  width: 100%;

}

.input-container {
  display: flex;
}

.iconFilterMobile {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 40px;
}

.menuOutlined {
  color: black;
}

.closeButton {
  position: absolute !important;
  left: 24px;
  width: 45px;
  height: 45px;
  font-size: 16px;
}

.title {
  font-weight: bold;

}

.titleContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 5px;
}

.actionsContainer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
  padding: 20px 20px;
  justify-content: space-between;
}

.btnSave {
  padding: 8px 16px;
  width: 55%;
  height: 48px;
  background: #1c64f2;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnSave:active,
.btnSave:focus {
  padding: 8px 18px;
  background: #4079ec;
  color: rgb(255, 255, 255);
}

.btnSave:hover {
  background: #316de4;
  padding: 5px 14px;
  cursor: pointer;
  color: white;
}


.btnClear,
.btnClear:focus {
  align-items: center;
  padding: 8px 16px;
  width: 40%;
  height: 48px;
  background: #ffffff;
  color: #1c64f2;
  border: 2px solid #1c64f2;
  border-radius: 24px !important;
  display: flex;
  font-weight: bold;
  justify-content: center;
}



.btnClear:active {
  border: 2px solid #1c64f2;
  padding: 8px 18px;
  background: #cccccc;
  color: #1c64f2;
}

.btnClear:disabled {
  border: none;
}

@media only screen and (max-width: 800px) {
  .iconFilterMobile {
    justify-content: start;
    margin-top: 1.3rem;
    margin-right: 1rem;
    max-height: 30px;
  }
}