.select-recommended-container {
  min-width: 35px;
  background: #f5f5f5;
  border-radius: 100px;
  padding-left: 10px;
  margin-right: 16px;

  @media only screen and (min-width: 980px) {
    display: unset;
  }

  @media only screen and (max-width: 694px) {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .select-recommended-container {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1280px) {
  .select-recommended-container {
    margin-right: 0px;
  }
}
