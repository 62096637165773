.category-container{
    display: flex;
}

.category-text {
    font-size: 16px;
    font-weight: 400;
    color: #777E8B;
}

.category-text-selected {
    font-size: 16px;
    font-weight: 400;
    color: #021120;
}

.category-checkbox{
    margin-top: 13px;
}

.collapse-category{
    width: 100%;
}