.content {
  min-height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #F1F1F1;

  &:hover {
    background-color: #F1F1F1;
  }

  .image {
    min-width: 42px;
    height: 42px;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-left: 20px;
  }

  .texts {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }

  .label {
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 24px;
    color: #000000;
  }

  .typeText {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

}
