.country-filter{
    display: flex;
    flex-direction: column;
}

.country-text {
    font-size: 16px;
    font-weight: 400;
    color: #777E8B;
    margin-bottom: 10px;
    margin-left: 0px!important;
}

.country-text-selected {
    font-size: 16px;
    font-weight: 400;
    color: #021120;
    margin-bottom: 10px;
    margin-left: 0px!important;
}

.country-img {
    margin-left: 8px;
    margin-right: 2px;
    margin-bottom: 2.5px;
}