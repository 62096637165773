@import '../../assets/styles/_images.scss';

.contenedor {
  background-image: $developers-background;
  height: 446px;
  width: 100%;
  position: relative;
  background-size: cover;
  // border-radius: 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.degrade {
  height: 446px;
  width: 85%;
  left: 0px;
  z-index: 0;
  position: absolute;
  background: linear-gradient(90.03deg, #01404D 27.18%, rgba(1, 64, 77, 0) 79.5%);

}

.developers {
  display: flex;
  font-size: 13px;
  font-weight: 400;
  line-height: 48px;
  color: #ffffff;
}

.text {
  display: flex;
  justify-content: space-between;
  font-weight: 900;
  font-size: 40px;
  color: #ffffff;
}

.textButton {
  font-weight: bold;
}

.button-upload {
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  color: black;
  margin-top: 20px;
  padding: 12px 20px;
  cursor: pointer;
}

@media only screen and (max-width: 694px) {
  .contenedor {
    background-image: url('../../assets/img/uploadMobile.png');
    height: 408px;
    width: 100%;
    position: relative;
    background-size: cover;
    // border-radius: 10px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 72px;
  }

  .text {
    font-size: 25px;
  }

  .degrade {
    height: 408px;

    width: 100%;
    left: 0px;
    z-index: 0;
    position: absolute;
    background: linear-gradient(0deg, #01404D 10.18%, rgba(1, 64, 77, 0) 79.5%);
  }
}