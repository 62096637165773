.select-date{
    min-width: 7rem;
}

.year-filter{
    display: flex;
    justify-content: space-between;
    margin-left: 8px;
}

.label{
    margin-right: 0.5rem;
}

.year-container{
    display: flex;
    flex-direction: column;
}