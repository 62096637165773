.inputContainer {
  width: 100%;
  position: relative;
}

.emailInput {
  height: 48px;
  width: 438px;
}

.sendButtonContainer {
  position: absolute;
  top: 0px;
  z-index: 2;
  right: 0px;
}

.sendButton {

  background: #1c64f2;
  border-radius: 24px !important;
  height: 48px;
  //width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 55px 12px 55px;
}

.recaptchaContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.captchaError {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -20px;
  color: #ff4d4f;
  font-size: 14px;
}

@media only screen and (max-width: 700px) {
  .emailInput {
    width: 100%;
  }

  .sendButton {
    width: 90%;
    padding-left: 10%;
    margin-left: 15%;
  }

}