.filter-title {
  display: flex;
}

.filter-text {
  font-size: 16px;

  :global(.ant-collapse-content-box) {
    padding-top: 6px !important;
    padding-left: 8px !important;
  }
}

.filter-text-double {
  font-size: 16px;

  :global(.ant-collapse-content-box) {
    padding-top: 0 !important;
    padding-left: 8px !important;
    padding-right: 0 !important;
  }
}

.titleFiters {
  font-size: 16px;
  line-height: 19px;
  color: #777e8b;
  font-weight: 400;
}

.divider {
  margin: 0px 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0 !important;
}

.reset-btn {
  display: flex;
  margin-left: 1rem;
  margin-top: 3rem;
  color: #021120;
  border-radius: 100px;
  border: none;
  background-color: #fafafa;
}

.iconFilterMobile {
  display: none;
}

.container-category {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  height: 25px;
}

.badge {
  background-color: #1890ff;
  color: white;
  height: 25px;
  width: 24px;
  border-radius: 20px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .iconFilterMobile {
    display: flex;
    margin-left: 10px;
    margin-top: 20px;
  }

  .menuOutlined {
    color: black;
  }

  .filter-text {
    font-weight: bold;
  }

  .reset-btn {
    display: none;
  }
}

//pantallas 1920 125% ZOOM

@media only screen and (min-width: 1250px) and (max-width: 1600px) {

  .filter-title {
    display: flex;
  }

  .filter-text {
    font-size: 16px;
  }

  .reset-btn {
    display: flex;
    margin-left: 2rem;
    margin-top: 3rem;
  }

  .iconFilterMobile {
    display: none;
  }

  .container-category {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
  }

  .badge {
    background-color: #1890ff;
    color: white;
    height: 25px;
    width: 24px;
    border-radius: 20px;
    text-align: center;
  }
}

//TABLETS
@media (min-width: 768px) and (max-width: 1199px) {

  .filter-title {
    display: flex;
  }

  .filter-text {
    font-size: 16px;
  }

  .reset-btn {
    display: flex;
    margin-left: 2px;
    margin-top: 3rem;
  }

  .iconFilterMobile {
    display: none;
  }

  .container-category {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .badge {
    background-color: #1890ff;
    color: white;
    height: 25px;
    width: 24px;
    border-radius: 20px;
    text-align: center;
  }
}