.container {
    position: relative;
    display: flex;
    //justify-content: center;
    align-items: center;
    width: 551;
    height: 156px;
    background: cover;
    background-image: url('../../assets/img/RectangleHowWorks.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
  }
  
.modal-wrap{
 overflow-x: hidden;
}

.modal-wrap::-webkit-scrollbar {
  display: none;
}


.modal-title{
    width: 300px;
    height: 64px;
    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
    color: white;
    margin-bottom: 14.55px;
    margin-top: 22px;
    letter-spacing: -1px;
}

.modal-title-l{
  width: 300px;
  height: 64px;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  color: white;
  margin-bottom: 14.55px;
  margin-top: 22px;
  letter-spacing: -1px;
}

.text-container{
    margin-left: 30.93px;
    color: white;
    z-index: 1;
}

.modal-text{
    font-weight: 400;
    font-size: 15px;
}

.par-text{
  color: #777E8B;
}

.degrade-left {
    position: absolute;
    width: 551px;
    height: 156px;
    background: linear-gradient(
      89.94deg,
      rgba(242, 244, 245, 0.0526304) -0.57%,
      rgba(0, 62, 111, 0) -0.56%,
      rgba(0, 62, 111, 0.114586) 13.71%,
      rgba(0, 62, 111, 0.184257) 24.68%,
      rgba(0, 62, 111, 0.259936) 32.88%,
      rgba(0, 62, 111, 0.339911) 38.84%,
      rgba(0, 62, 111, 0.422475) 43.12%,
      rgba(0, 62, 111, 0.505918) 46.24%,
      rgba(0, 62, 111, 0.588531) 48.77%,
      rgba(0, 62, 111, 0.668605) 51.23%,
      rgba(0, 62, 111, 0.744429) 54.17%,
      rgba(0, 62, 111, 0.754888) 54.18%,
      rgba(0, 62, 111, 0.814296) 58.13%,
      rgba(0, 62, 111, 0.876496) 63.66%,
      rgba(0, 62, 111, 0.929319) 71.29%,
      rgba(0, 62, 111, 0.971057) 81.58%,
      #003e6f 95.05%
    );
    border-radius: 10px;
  }
  
  .degrade-left {
    left: 0;
    transform: rotateY(180deg);
  }

  .offsetting-container{
    margin-top: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12.6154px;
    gap: 12.62px;
    background: rgba(255, 255, 255, 0.28);
    border-radius: 6.30769px;
    width: 206.87px;
    height: 54px;
  }

  .icon-container{
    width: 63.08px;
    height: 28.38px;
    background: #FFFFFF;
    border-radius: 3.15385px;
    z-index: 1;
    display: flex;
    justify-content: center;
  }

  .text-tag{
    z-index: 1;
    width: 140px;
    height: 28px;
    font-style: normal;
    font-weight: 600;
    font-size: 9.5px;
    line-height: 14px;
    color: white;
  }

  .number-tag{
    color: #3dd598;
    margin-top: 3px;
    margin-left: 2px;
  }

  .icon-tag{
    margin-top: 5px;
  }

  .par-container-gral{
    display: flex;
    margin-top: 22px;
    justify-content: space-around;
    
  }

  .par-container{
    width: 251px;
    height: 192px;
  }

  .title-container{
    display: flex;
    margin-bottom: 16px;
  }

  .number-icon{
    border: 1px solid #021120;
    border-radius: 10px;
    width: 22px;
    height: 22px;
    margin-right: 20px;
    margin-top: 1px;
  }

  .number-icon-text{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    width: 8px;
    height: 20px;
    margin-left: 4.5px;
    margin-bottom: 1px;
  }

  .title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
  }

  .why-climate{
    margin-top: 50px;
  }

  .title{
    text-align: center;
  }

  @media only screen and (max-width: 620px) {
    .degrade-left{
      width: 451px;
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column;
    }

    .offsetting-container{
      margin-top: 0px;
    }

    .modal-title{
      margin-top: 10px;
      height: 50px;
    }
    
    .par-container-gral{
      align-items: center;
      flex-direction: column;
    }

    .par-container{
      width: auto;
      height: auto;
      margin-bottom: 20px;
    }
  }


  @media only screen and (max-width: 620px) {
    .degrade-left{
      width: 300px;
    }

    .modal{
      top: 0px
    }
  }
