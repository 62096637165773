.cardSkeleton {
  width: 288px;
  height: 312px;
  opacity: 0.5;
  border-radius: 8px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.2), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.2);
}

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.mobile-list{
  justify-content: center;
  margin-top: 32px;
}
@media only screen and (max-width: 692px) {
  .mobile-list{
    justify-content: center;
    //max-width: 320px;
    margin-left: 0px;
    row-gap: 16px !important;
    margin-top: 16px;
  }

  .cardSkeleton {
    width: 328px;
    height: 224px;
  }
  
}