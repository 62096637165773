.container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  @media only screen and (max-width: 692px) {
    max-width: 360px;
    min-width: 360px;
    width: fit-content;
    margin-inline: 18%;
  }

  @media only screen and (max-width: 450px) {
    max-width: 360px;
    min-width: 360px;
    width: fit-content;
    margin-inline: 5%;
  }
}

.notification-container {
  display: flex;
  justify-content: center;
}

.description {
  text-align: left;
  margin-left: 16px;
  margin-top: 14.5px;
}

.notification {
  margin-top: 85vh;
  width: 340px;
  min-height: 177px;
  margin-left: 26vh;

  @media only screen and (max-width: 1680px) {
    margin-left: 16vh;
  }

  @media only screen and (max-width: 1440px) {
    margin-left: 6vh;
  }

  @media only screen and (max-width: 1366px) {
    margin-left: 2vh;
  }

  @media only screen and (max-width: 600px) {
    margin-left: 0vh;
  }


  @media only screen and (max-height: 812px) {
    margin-top: 80vh;
  }
}

.content {
  max-width: 1284px;
  min-width: 1284px;

  @media only screen and (max-width: 1365px) {
    max-width: 960px;
    min-width: 960px;
  }

  @media only screen and (max-width: 979px) {
    max-width: 640px;
    min-width: 640px;
  }

  @media only screen and (max-width: 692px) {
    max-width: 360px;
    min-width: 360px;
  }
}

.mobile-container {
  max-width: 1284px;
  min-width: 1284px;

  @media only screen and (max-width: 1365px) {
    max-width: 960px;
    min-width: 960px;
  }

  @media only screen and (max-width: 979px) {
    max-width: 640px;
    min-width: 640px;
  }

  @media only screen and (max-width: 692px) {
    max-width: 360px;
    min-width: 360px;
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #021120;
}

.notificationTitle {
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  margin-left: 16px;
}

.showMoreButton,
.showMoreButton:focus {
  align-items: center;
  padding: 8px 16px;
  width: 328px;
  height: 48px;
  background: #ffffff;
  color: #1c64f2;
  border: 2px solid #1c64f2;
  border-radius: 24px !important;
  display: flex;
  font-weight: bold;
  justify-content: center;
}

.showMoreButton:active {
  border: 2px solid #1c64f2;
  padding: 8px 18px;
  background: #cccccc;
  color: #1c64f2;
}

.showMoreButton:disabled {
  border: none;
}

.title-container {
  margin-bottom: 42px;
  margin-left: 16px;
}

.infinite-container {
  overflow: unset !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.infinite-container-2 {
  overflow: unset !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.divSkeletonCategoryFilters {
  margin-bottom: 50px;
}

.skeletonCategoryFilter1 {
  margin-left: 18%;
}

.skeletonCategoryFilter2 {
  margin-left: 11%;
}

.skeletonCategoryFilter3 {
  margin-left: 11%;
}

.filter-section {
  position: relative;
  max-width: 272px;
  min-width: 272px;
  width: 100%;

  @media only screen and (min-width: 980px) {
    display: unset;
  }
}

.content-container {
  flex-wrap: nowrap;
  margin-bottom: 5rem;
}

.results-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-right: 16.5rem;
  width: 1233px;
}

.col-container {
  padding-left: 0px !important;
  max-width: 80%;
}

.general {
  display: flex;
  justify-content: center;
  margin-left: 200px;
}

.cardsDesktop {
  display: flex;
}

.cardsMobile {
  display: none;
}

.divSkeletonCategoryFiltersTablet {
  display: none;
}

.containerSkeletonTablet {
  display: none;
}

.filter-section-mobile {
  display: none;

  @media only screen and (max-width: 979px) {
    display: unset;

  }

  @media only screen and (max-width: 695px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeAnimation 1s ease-in forwards;
}

@keyframes fadeAnimation {
  from {
    opacity: 0;
    /* Comienza con opacidad 0 */
  }

  to {
    opacity: 1;
    /* Termina con opacidad 1 */
  }
}


.grid-column {
  margin-top: 12px;
  max-width: 960px;
  min-width: 960px;

  @media only screen and (max-width: 979px) {
    max-width: 640px;
    min-width: 640px;
  }

  @media only screen and (max-width: 692px) {
    max-width: 360px;
    min-width: 360px;
    margin-top: 0px;
  }

}

.mobile-list,
.mobile-list-show {
  max-width: 960px;
  min-width: 960px;

  @media only screen and (max-width: 979px) {
    max-width: 640px;
    min-width: 640px;
  }

  @media only screen and (max-width: 692px) {
    max-width: 360px;
    min-width: 360px;
  }

}

.btnApply {
  padding: 8px 16px;
  width: 100%;
  height: 48px;
  background: #1c64f2;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  max-width: 239px;
  bottom: 30px;
  margin-left: 16px;
  font-weight: bold;
}

.btnApply:active,
.btnApply:focus {
  padding: 8px 18px;
  background: #4079ec;
  color: rgb(255, 255, 255);
}

.btnApply:hover {
  background: #316de4;
  padding: 5px 14px;
  cursor: pointer;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .CategoryFilter-desktop {
    display: none;
  }

  .title {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1280px) {
  .infinite-container {
    width: 100%;
  }

  .infinite-container-2 {
    width: 100%;
  }

  .title {
    margin-left: 0px;
  }
}



@media only screen and (max-width: 780px) {
  .infinite-container {
    overflow: unset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .infinite-container-2 {
    overflow: unset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 692px) {
  .mobile-list {
    row-gap: 16px !important;
  }

  .mobile-list-show {
    justify-content: center;
    row-gap: 16px !important;
  }

  .title-container {
    justify-content: center;
    padding-left: 0px;
    margin-bottom: 24px;
    max-width: 288px;
  }
}
