.container {
  height: 100%;
  display: flex;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: -0.5px;
  text-align: left;
  margin: 0px;
  margin-bottom: 24px;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px;
}

.button {
  align-items: center;
  padding: 8px 16px;
  height: 48px;
  color: black;
  background: rgba(255, 255, 255, 1);

  border: none;
  border-radius: 24px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;

  margin-top: 60px;
}