.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.category-tag,
.first-clone,
.last-clone {
  display: flex;
  z-index: 5;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  transition: all 0.5s ease;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 12px;
  border: 1px solid #0000000F;
  color: #777E8B;
  ;
}

.category-tag-disabled {
  cursor: not-allowed;
  display: flex;
  z-index: 5;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  transition: all 0.5s ease;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 12px;
  border: 1px solid #0000000F;
  color: #777E8B;
}

.first-clone {
  position: absolute;
  width: calc(95% - 24px);
  top: 4px;
  z-index: 2;
}

.last-clone {
  position: absolute;
  width: calc(90% - 24px);
  top: 8px;
  z-index: 0;
}

.category-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #021120;
  width: 100%;
}

.category-text-disabled {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: gray;
  width: 100%;
}

.category-tag p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.contenedor {
  border-radius: 8px;
  position: relative;
  margin-top: 20px;

  @media only screen and (max-width: 694px) {
    margin-top: 110px;
  }

  align-items: center;
}

.box {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  background: #f5f5f5;
  margin: 0 0 25px;
  overflow: hidden;
  padding: 50px;
  border-radius: 8px;

  @media only screen and (max-width: 694px) {
    margin: 0 0 72px;
  }
}

.textTitle {
  text-align: start;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
  color: #021120;
  line-height: 29px;
}

.category-tag:hover {
  cursor: pointer;
  box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
  transition: all 0.5s ease;
}