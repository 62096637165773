.container{
    display: flex;
    justify-content: space-between;
}

.price-filter-container{
    margin-left: 10px;
}

.price-container{
    width: 8.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding-right: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span{
        margin-top: 0.25rem;
        margin-right: 0.25rem;
    }
}

.price-input{
    min-width: 7.3rem;
}

.text{
    margin-right: 0.5rem;
}

.input-container{
    display: flex;
    flex-direction: column;
}

