.category-filter {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 40px;
}

.category-filter-mobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .category-filter {
    display: none;
  }

  .category-filter-mobile {
    display: flex;
    justify-content: center;
    margin-left: 8%;
  }

  .category-filter::-webkit-scrollbar {
    display: none;
  }

  .NameFilters {
    font-size: 18px;
    font-weight: 800;
  }
}

@media only screen and (max-width: 695px) {

  .category-filter-mobile {
    display: none;
  }

}
