.popular-filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  flex: 1;
  padding-left: 1rem;

  :global .popular-filters-label {
    color: #777e8b;
  }

  :global .popular-filters {
    display: flex;
    justify-content: space-evenly;
    flex: 1;
    flex-wrap: wrap;

    .popular-filter {
      cursor: pointer;
      position: relative;
      width: 250px;
      height: 50px;

      .filter-label {
        border: solid 1px #777e8b19;
        text-align: center;
        position: absolute;
        top: 0;
        width: 250px;
        background: #f1f1f5;
        border-radius: 0.6rem;
        padding: 0.8rem 1rem;
        transition: all 400ms;
        z-index: 3;
        font-weight: bold;

      }

      .shadow {
        position: absolute;
        width: 230px;
        height: 50px;
        bottom: -3px;
        left: 10px;
        border: solid 1px #777e8b19;
        border-radius: 0.3rem;
        z-index: 2;
        background: #f1f1f5;

        &.two {
          width: 210px;
          bottom: -8px;
          left: 20px;
          z-index: 1;
        }
      }

      &.selected,
      &:hover {

        .filter-label,
        .shadow {
          border-color: #777e8b60;
        }
      }
    }
  }

}

.popular-filters-container-mobile {
  width: 19rem;
  background: transparent;

  border-radius: 10px;

}

.popular-text-filter {
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  font-size: 16px;

  color: #2B2E33;
  cursor: pointer;

  &:hover {
    font-size: 18px;
  }
}

.labelSelected {
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.popular-text-filter-selected {
  color: #2B2E33;
  font-weight: bold;
}

.filter-text {
  font-size: 16px;

  :global(.ant-collapse-content-box) {
    padding-top: 6px !important;
    padding-left: 8px !important;
  }

  :global(.ant-collapse-content) {
    background-color: transparent !important
  }
}

.titleFilters-selection {
  color: grey;
  font-size: 14px;
}

.titleFilters {
  text-align: center;
  font-size: 18px;
}