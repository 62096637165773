.standard-filter{
    display: flex;
    flex-direction: column;
}

.standard-text {
    font-size: 16px;
    font-weight: 400;
    color: #777E8B;
}

.standard-text-selected {
    font-size: 16px;
    font-weight: 400;
    color: #021120;
}