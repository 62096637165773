.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 999;
    max-height: 387px;
    overflow-y: auto;
    box-shadow: -3px 17px 41px -6px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: -3px 17px 41px -6px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: -3px 17px 41px -6px rgba(0, 0, 0, 0.35);

    @media screen and (max-width: 800px) {
      width: 90%;
      transform: translate(-50%, -10%);
    }

    &::-webkit-scrollbar {
      width: .1em;
      border-radius: 0.3em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
      border-radius: 0.3em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #003A6C;
      border-radius: 0.3em;
    }
  }
}