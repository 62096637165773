.textMargin {
  position: relative;
  top: -8px;
}

.image {
  width: 25px;
  height: 20px;
}

.text {
  color: #777e8b;
  margin-left: 10px;
}




.modal-title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #ffffff;
  margin: 0px;
  margin-bottom: 16px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.info {
  margin-left: 32px;

  margin-top: 68px;
  width: 277px;
}

.custom-input-number {
  width: 100% !important;
  margin-top: 3px !important;
}

.ok-button,
.cancel-button {
  background: #1c64f2;
  border-radius: 24px !important;
  height: 48px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.cancel-button {
  background: #ffffff;
  border: 1px solid #777e8b;
  color: #777e8b;
}
