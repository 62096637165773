.continent-container{
    display: flex;
}

.continent-text {
    font-size: 16px;
    font-weight: 400;
    color: #777E8B;
}

.continent-text-selected {
    font-size: 16px;
    font-weight: 400;
    color: #021120;
}
.custom {
    :global(.ant-collapse-content-box) {
        padding-top: 6px !important;
        padding-left: 0 !important;
    }
    :global(.ant-collapse-header) {
        padding: 8px 16px !important;
    }
}

.continent-checkbox{
    margin-top: 9px;
}

.collapse-continent{
    width: 100%;
}