.type-filter{
    width: 95%;
    margin-right: 5%;
}

.kind-container{
    display: flex;
    flex-direction: column;
}

.biodiversity{
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: #003E6F;
    ;
}

.btn-x{
    border-radius: 100px!important;
    padding: 0;
    margin-top: 5px;
    height: 20px;
    min-width: 20px;
    border-color: #1C64F2;
    background: #1C64F2;
}

.image-circle {
    margin-bottom: 4px;
    margin-right: 8px;
}

.text-checkbox {
    font-size: 16px;
    margin-left: 8px;
}

.kind-text {
    font-weight: 400;
    color: #777E8B;
}

.kind-text-selected {
    font-size: 16px;
    font-weight: 400;
    color: #021120;
}