.container {
  display: flex;
  background-image: url('../../../assets/img/exitIntentNews.png');
  background-size: cover;
  width: 570px;
  height: 310px;
  border-radius: 9px;
  background-position: 50% 50%;
  overflow: hidden;
}

.gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  mix-blend-mode: normal;
  transform: rotateY(180deg) !important;
  border-radius: 9px;
  background: linear-gradient(89.94deg,
      rgba(242, 244, 245, 0.0526304) -0.57%,
      rgba(0, 62, 111, 0) -0.56%,
      rgba(0, 62, 111, 0.114586) 13.71%,
      rgba(0, 62, 111, 0.184257) 24.68%,
      rgba(0, 62, 111, 0.259936) 32.88%,
      rgba(0, 62, 111, 0.339911) 38.84%,
      rgba(0, 62, 111, 0.422475) 43.12%,
      rgba(0, 62, 111, 0.505918) 46.24%,
      rgba(0, 62, 111, 0.588531) 48.77%,
      rgba(0, 62, 111, 0.668605) 51.23%,
      rgba(0, 62, 111, 0.744429) 54.17%,
      rgba(0, 62, 111, 0.754888) 54.18%,
      rgba(0, 62, 111, 0.814296) 58.13%,
      rgba(0, 62, 111, 0.876496) 63.66%,
      rgba(0, 62, 111, 0.929319) 71.29%,
      rgba(0, 62, 111, 0.971057) 81.58%,
      #003e6f 95.05%);
}

.modal-title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 39px;
  letter-spacing: -1px;
  color: #ffffff;
  margin: 0px;
  margin-bottom: 16px;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  margin-bottom: 22px;
}

.info {
  margin-left: 32px;

  margin-top: 68px;
  width: 277px;
}

.image1 {
  position: absolute;
  top: 0px;
  left: 337px;
}

.newsCol {
  display: flex;
  width: 238px;
  justify-content: flex-end;
}

.newsContainer {
  position: absolute;
  top: 30px;
  border-radius: 10px 0px 0px 0px;
  background-color: white;
  width: 238px;
  padding: 30px 24px;

}

.newsTag {
  display: flex;
  background: rgba(0, 62, 111, 1);
  height: 24px;
  padding: 0px 8px;
  border-radius: 16px;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ok-button,
.cancel-button {
  background: #1c64f2;
  border-radius: 24px !important;
  height: 48px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.cancel-button {
  background: #ffffff;
  border: 1px solid #777e8b;
  color: #777e8b;
}

.form {
  margin-top: 16px;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    padding-right: 10px
  }

  .newsContainer {
    top: 100%;
    right: 50%;
    border-radius: 10px;
  }

  .info {
    width: 340px;
    margin-top: 40px;
  }

  .title {
    width: 330px;
    padding-right: 10px
  }

}