.category-tag {
  background: #ffffff;
  height: 48px;
  width: 272px;
  border-radius: 8px;
  transition: all 0.5s ease;
}

.results {
  color: #bcbfc4;
  font-weight: normal;
}

.category-text {
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #021120;
}

.category-tag:hover {
  cursor: pointer;
  box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
  transition: all 0.5s ease;
}

// dispositivos mobiles
@media only screen and (max-width: 600px) {
  .category-tag {
    background: #ffffff;
    height: 48px;
    width: 272px;
    border-radius: 8px;
    transition: all 0.5s ease;
  }

  .results {
    color: #bcbfc4;
    font-weight: normal;
  }

  .category-text {
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #021120;
  }

  .category-tag:hover {
    cursor: pointer;
    box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    transition: all 0.5s ease;
  }
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
  .category-tag {
    background: #ffffff;
    height: 48px;
    width: 200px;
    border-radius: 8px;
    transition: all 0.5s ease;
    margin: 5px auto;
  }
}

// Pantallas HD
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .category-tag {
    background: #ffffff;
    height: 48px;
    width: 200px;
    border-radius: 8px;
    transition: all 0.5s ease;
  }

  .category-text {
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #021120;
  }

  .category-tag:hover {
    cursor: pointer;
    box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    transition: all 0.5s ease;
  }
}

//pantallas 1920 125% ZOOM

@media only screen and (min-width: 1250px) and (max-width: 1600px) {
  .category-tag {
    background: #ffffff;
    height: 48px;
    width: 200px;
    border-radius: 8px;
    transition: all 0.5s ease;
  }

  .category-text {
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #021120;
  }

  .category-tag:hover {
    cursor: pointer;
    box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    transition: all 0.5s ease;
  }
}

//TABLETS
@media (min-width: 768px) and (max-width: 1199px) {
  .category-tag {
    background: #ffffff;
    height: 48px;
    width: 272px;
    border-radius: 8px;
    transition: all 0.5s ease;
  }

  .results {
    color: #bcbfc4;
    font-weight: normal;
  }

  .category-text {
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #021120;
  }

  .category-tag:hover {
    cursor: pointer;
    box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 9px 36px -8px rgba(0, 0, 0, 0.75);
    transition: all 0.5s ease;
  }
}