.sdg-filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sdg-icon {
  width: 20px;
  height: 20px;
  // margin-top: 2px;
}

.sdg-text {
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  color: #777e8b;
}

.sdg-text-selected {
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  color: #021120;
}

.divSdg {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2px;
}

.divRadio {
  width: 100%;
}

.divContenedor {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 230px;
}
