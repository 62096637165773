.text {
    font-size: 16px;
    color: #777e8b;
    font-weight: 400;
    margin-left: 0px!important;
    margin-bottom: 5px;
    margin-top: 5px;
}

.text-selected {
    font-size: 16px;
    font-weight: 400;
    color: #021120;
    margin-left: 0px!important;
    margin-bottom: 5px;
    margin-top: 5px;
}

.type-filter{
    display: flex;
    flex-direction: column;
}
