@import '../../assets/styles/_images.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 380px;
  background: cover;
  background-image: $front-page-background;
  background-repeat: no-repeat;
  background-size: cover;
}

.degrade-left {
  position: absolute;
  width: 45%;
  height: 380px;
  background: linear-gradient(89.94deg,
      rgba(242, 244, 245, 0.0526304) -0.57%,
      rgba(0, 62, 111, 0) -0.56%,
      rgba(0, 62, 111, 0.114586) 13.71%,
      rgba(0, 62, 111, 0.184257) 24.68%,
      rgba(0, 62, 111, 0.259936) 32.88%,
      rgba(0, 62, 111, 0.339911) 38.84%,
      rgba(0, 62, 111, 0.422475) 43.12%,
      rgba(0, 62, 111, 0.505918) 46.24%,
      rgba(0, 62, 111, 0.588531) 48.77%,
      rgba(0, 62, 111, 0.668605) 51.23%,
      rgba(0, 62, 111, 0.744429) 54.17%,
      rgba(0, 62, 111, 0.754888) 54.18%,
      rgba(0, 62, 111, 0.814296) 58.13%,
      rgba(0, 62, 111, 0.876496) 63.66%,
      rgba(0, 62, 111, 0.929319) 71.29%,
      rgba(0, 62, 111, 0.971057) 81.58%,
      #003e6f 95.05%);
  left: 0;
  transform: rotateY(180deg);
}

.titleBack {
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  color: white;
}

.divTitle {
  display: flex;
  flex-direction: column;
  z-index: 1;
  text-align: start;
  margin-bottom: 48px;
  padding-left: 15%;
  width: 100%;
  max-width: 900px;
}

.btnShopNow {
  background-color: white;
  text-align: center;
  width: 100% !important;
  min-height: 48px;
  max-width: 165px;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 24px;
  border-radius: 24px !important;
  border-width: 0;
  color: black;
  cursor: pointer;
}

.btnShopNow:hover {
  color: #1c64f2 !important;
  border: 1px solid #1c64f2 !important;
}

.btnCalculate {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px;
  border-radius: 24px !important;
  padding: 0px 24px;
  border: 1px white solid;

  .linkCalculate {
    color: white;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
}

.btnCalculate:hover {
  color: white !important;
  border: 1px solid #1c64f2 !important;
}

.divBtns {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  text-align: center;
  padding-right: 8px;
}

.btnCalculateMobile {
  display: none;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .btnCalculateMobile {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px;
    border-radius: 24px !important;
    padding: 0px 24px;
    border: 1px white solid;

    .linkCalculateMobile {
      color: #ffffff;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px;
    }
  }

  .btnCalculate {
    display: none !important;
  }

  .container {
    background-image: url('../../assets/img/background-mobile.png');
  }

  .degrade-left {
    display: none;
  }

  .degrade-right {
    position: absolute;
    width: 100%;
    height: 385px;
    background: linear-gradient(rgba(242, 244, 245, 0.0526304) -0.57%,
        rgba(0, 62, 111, 0) -0.56%,
        rgba(0, 62, 111, 0.114586) 13.71%,
        rgba(0, 62, 111, 0.184257) 24.68%,
        rgba(0, 62, 111, 0.259936) 32.88%,
        rgba(0, 62, 111, 0.339911) 38.84%,
        rgba(0, 62, 111, 0.422475) 43.12%,
        rgba(0, 62, 111, 0.505918) 46.24%,
        rgba(0, 62, 111, 0.588531) 48.77%,
        rgba(0, 62, 111, 0.668605) 51.23%,
        rgba(0, 62, 111, 0.744429) 54.17%,
        rgba(0, 62, 111, 0.754888) 54.18%,
        rgba(0, 62, 111, 0.814296) 58.13%,
        rgba(0, 62, 111, 0.876496) 63.66%,
        rgba(0, 62, 111, 0.929319) 71.29%,
        rgba(0, 62, 111, 0.971057) 81.58%,
        #003e6f 95.05%);
    mix-blend-mode: normal;
  }

  .titleBack {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    color: white;
  }

  .divTitle {
    font-size: 32px;
    margin-top: 45px;
    padding-left: 8%;
    padding-right: 12%;
  }

  .btnShopNow {
    width: 118px;
    height: 40px;
    max-width: 130px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    border-radius: 24px !important;
    border: none;
    margin-left: 2%;
  }

  .btnShopNow:hover {
    color: #1c64f2 !important;
    border: 1px solid #1c64f2 !important;
  }

  .btnCalculate {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    border-radius: 24px !important;
    display: flex;
  }

  .btnCalculate:hover {
    color: white !important;
    border: 1px solid #1c64f2 !important;
  }
}