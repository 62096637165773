.text {
    font-size: 16px;
    font-weight: 400;
    color: #777e8b;
}
 .text-selected {
    font-size: 16px;
    font-weight: 400;
    color: #021120;
 }

.characteristics-filter{
    display: flex;
    flex-direction: column;
}
.characteristic-img{
    width: 25px;
    height: 25px;
    margin-right: 3px;
}

.text-container{
    margin-left: 8px;
}
