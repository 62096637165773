.impact-filter{
    display: flex;
    flex-direction: column;
}

.impact-text{
    font-size: 16px;
    font-weight: 400;
    color: #777e8b;
}

.impact-text-selected {
    font-size: 16px;
    font-weight: 400;
    color: #021120;
}