.container {
  padding: 10px 40px 0 20px;
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: #ffffff;

  .content {
    background-color: #ffffff;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .results {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #777E8B;
      margin-bottom: 10px;
    }

    .text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #777E8B;
      margin-top: 7px;
      cursor: pointer;
      transition: all .2s cubic-bezier(.165,.84,.44,1);
      outline: 0 solid rgba(0,72,255,.071);
      border-radius: 4px;
      background: rgba(0,72,255,0);

      &:hover {
        background: rgba(0,72,255,.071);
        outline: 8px solid rgba(0,72,255,.071);
        transition: 0.1s;
      }
    }
  }
}
