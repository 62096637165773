.container {
  padding: 16px 16px;
  margin: 32px 0px;
}

.banner {
  //height: 116px;
  width: 100%;
  //min-height: 400px;
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  padding: 24px;
}

.cardTag {
  height: 34px;
  padding: 8px 16px 8px 16px;
  border-radius: 100px;
  background: rgba(52, 79, 175, 0.08);

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;

  color: rgba(28, 100, 242, 1);
  margin-bottom: 38px;
}

.body{
  height: 100%;
}